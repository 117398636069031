import axios from 'axios';
import global from '../../components/Global';

let base = global.doartSystemBase;

// 创建用户信息
export const createSysUserInfo = params => {

    let formData = new FormData();
    if (params.fileList && params.fileList.length > 0) {

        formData.append('imageFile', params.fileList[0].raw);
    }
    formData.append("orgId", params.dto.orgId);
    formData.append("roleId", params.dto.roleId);
    formData.append("loginName", params.dto.loginName);
    formData.append("userName", params.dto.userName);
    formData.append("password", params.dto.password);
    formData.append("email", params.dto.email);
    formData.append("temperatureUnit", params.dto.temperatureUnit);
    formData.append("areaCode", params.dto.areaCode);
    formData.append("phone", params.dto.phone);
    formData.append("status", params.dto.status);
    formData.append("headSculpture", params.dto.headSculpture);
    return axios({

        url: global.doartSystemBase + '/user/createSysUserInfo',
        method: 'post',
        data: formData
    });
};

// 修改用户信息
export const modifySysUserInfo = params => {

    let formData = new FormData();
    if (params.fileList && params.fileList.length > 0 && params.fileList[0].status === 'ready') {

        formData.append('imageFile', params.fileList[0].raw);
    }
    formData.append("id", params.dto.id);
    formData.append("orgId", params.dto.orgId);
    formData.append("roleId", params.dto.roleId);
    formData.append("loginName", params.dto.loginName);
    formData.append("userName", params.dto.userName);
    if (params.dto.password !== '') {

        formData.append("password", params.dto.password);
    }
    formData.append("email", params.dto.email);
    formData.append("temperatureUnit", params.dto.temperatureUnit);
    formData.append("areaCode", params.dto.areaCode === null ? '' : params.dto.areaCode);
    formData.append("phone", params.dto.phone === null ? '' : params.dto.phone);
    formData.append("status", params.dto.status);
    formData.append("headSculpture", params.dto.headSculpture === null ? '' : params.dto.headSculpture);
    return axios({

        url: global.doartSystemBase + '/user/modifySysUserInfo',
        method: 'post',
        data: formData
    });
};

// 删除用户信息
export const removeSysUserInfo = params => {

    return axios.post(base + '/user/removeSysUserInfo', params);
};

// 查询用户信息
export const querySysUserInfoList = params => {

    return axios.post(base + '/user/querySysUserInfoList', params);
};

// 根据用户ID查询用户信息
export const querySysUserInfoById = params => {

    return axios.post(base + '/user/querySysUserInfoById', params);
};

// 登录平台
export const requestLogin = params => {

    return axios.post(global.serverSite + '/doartIot/auth/login', params);
};

// 登出平台
export const requestLogout = () => {

    return axios.post(global.serverSite + '/doartIot/auth/logOut').then(res => res.data);
};
