<template>
    <el-container class="container">
        <div class="login-box">
            <div class="title-box">
                <el-image :src="require('../assets/image/login/company-icon.png')"></el-image>
                <div class="platform-name">
                    <h3 class="title">羲和智慧云平台</h3>
                </div>
            </div>
            <div class="content-box">
                <div class="errorInfo">
                    <span v-show="errorInfo">{{ errorMsg }}</span>
                </div>
                <el-form :model="loginForm" :rules="rules" ref="loginForm">
                    <el-form-item prop="username">
                        <el-input type="text" v-model="loginForm.username" prefix-icon="el-icon-user-solid"
                                  auto-complete="off" placeholder="请填写账号">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-lock" show-password
                                  auto-complete="off" placeholder="请填写密码">
                        </el-input>
                    </el-form-item>
                    <el-row>
                        <el-col :span="11">
                            <el-form-item>
                                <el-select v-model="area" @change="areaChange">
                                    <template slot="prefix">
                                        <span>
                                            <el-image style="width: 18px;height: 18px;margin: 11px 0 0 3px" :src="require('../assets/image/login/area.png')"></el-image>
                                        </span>
                                    </template>
                                    <el-option
                                        v-for="(item, index) in areaOptions"
                                        :key="item.url + item.areaName"
                                        :label="item.areaName"
                                        :value="index">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <!-- 布局占位 -->
                            <el-form-item/>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item prop="password">
                                <el-select v-model="language">
                                    <template slot="prefix">
                                        <span>
                                            <el-image style="width: 18px;height: 18px;margin: 11px 0 0 3px" :src="require('../assets/image/login/language.png')"></el-image>
                                        </span>
                                    </template>
                                    <el-option
                                        v-for="item in languageOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-button type="primary" class="login-btn" @click.native.prevent="login" :loading="loginLoading">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="filings-box">
            <div style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-right: 10px">
                <p class="infoStyle" style="float:left;height:20px;line-height:20px;margin: 0 0 0 5px; color:white">
                    黄山东安新高能源科技有限公司
                </p>
            </div>
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-right: 10px">
                <p class="infoStyle" style="float:left;height:20px;line-height:20px;margin: 0 0 0 5px;">
                    皖ICP备20007506号-2
                </p>
            </a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34102202000254"
               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img src="../assets/image/psbicon.png" style="float:left;" alt=""/>
                <p class="infoStyle" style="float:left;height:20px;line-height:20px;margin: 0 0 0 5px;">
                    皖公网安备 34102202000254号
                </p>
            </a>
        </div>
    </el-container>
</template>
<script>
import { requestLogin } from "@/api/sys/userApi";
import { setToken } from "@/api/auth";
import global from '@/components/Global';

export default {

    data() {

        return {

            loginLoading: false,
            errorInfo: false,
            errorMsg: '',
            loginForm: {

                username: '',
                password: ''
            },
            rules: {

                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            },
            checked: true,
            area: 0,
            areaOptions: global.areaOptions,
            language: '简体中文',
            languageOptions: [{

                value: '简体中文',
                label: '简体中文'
            }]
        };
    },
    methods: {

        login: async function () {

            let params = {

              loginType: '5',
              loginName: this.loginForm.username,
              password: this.loginForm.password,
              platform: 'es-manager',
            };
            this.loginLoading = true;
            const res = await requestLogin(params);
            this.loginLoading = false;
            if (res.data && res.data.token) {

                setToken(res.data.token)
                this.$store.commit('saveIsLogin', true);
                this.$store.commit('saveUserInfo', res.data);
                this.errorInfo = false;
                await this.$router.push({path: '/home'});
            } else {

                this.errorMsg = res.msg
                this.errorInfo = true;
            }
        },
        areaChange(e) {

            window.location.href = this.areaOptions[e].url;
        }
    },
    mounted() {

        let index = this.areaOptions.findIndex(obj => obj.url.includes(window.location.host));
        if (index > -1) {

            this.area = index;
        }
    }
}
</script>
<style lang="scss" scoped>
.container {

    height: 100%;
    width: 100%;
    background-image: url("../assets/image/login/background-img.jpg");
    position: absolute;
    background-size: 100%;
    background-repeat: round;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-box {

        border-radius: 8px;
        width: 420px;
        padding: 60px 35px 38px 35px;
        background: rgba(17, 40, 108, 0.3);
        .title-box {

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100px;

            .platform-name {

                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 24px;
                margin-left: 20px;
                margin-top: 30px;
            }
        }

        .content-box {

            .errorInfo {

                color:red;
                text-align: center;
                height:40px;
            }

            .login-btn {

                width:100%;
                font-size: 16px;
                background: #0055B1;
                border-color: #0055B1;
                color: #fff;
            }
        }
    }
}

/*修改input高度*/
.el-input::v-deep .el-input__inner {
    width: 100%;
    height: 50px;
}

/*备案相关样式*/
.filings-box {

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    text-align: center;
}

a {

    text-decoration:none
}

a p {

    color : white;
}

a:hover p {

    color : white;
}
</style>
